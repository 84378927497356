<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import LoaderProcess from "../../../components/widgets/loaderProcess.vue";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import { Api } from "../../../helpers";
import displayLiteCard from "../../../components/widgets/card/displayLiteCard.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    LoaderProcess,
    displayLiteCard,
  },

  data() {
    return {
      nbreElement: null,
      pageSize: 16, // Nombre d'éléments à afficher sur chaque page
      currentPage: 0, // La page actuelle
      startDate: "",
      endDate: "",
      titre: "",
      progress: 0,
      searchQuery: "",
      process: false,
      modalOk: false,
      spinner: false,
      campagneList: [],
      fileCampagne: "images",
      campagne: {
        title: "",
        annonceur: "",
        startDate: "",
        endDate: "",
        description: "",
        durationPassage: "",
        message: "",
        url: "",
        frequence: 0,
        budget: 0,
      },
      title: "Liste de Vidéos",
      items: [
        {
          text: "Campagne",
          // href: "/projects/categories",
        },
        {
          text: "Display Lite",
          active: true,
        },
      ],

      activeFilter: "all",
      filteredVideos: [],
    };
  },
  methods: {
    filterVideos(filter) {
      if (filter === "all") {
        this.filteredVideos = this.videos;
      } else if (filter === "published") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === true
        );
      } else if (filter === "unpublished") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === false
        );
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },
    // Show a modal dialog
    showModal() {
      this.modalOk = true;
    },

    // videoSelect(event) {
    //   this.selectedFile = event.target.files[0]; // Get the selected image or video
    //   console.log(this.selectedFile);
    //   this.video.titre = this.selectedFile.name;
    //   const file = event.target.files[0];
    //   const video = document.createElement("video");
    //   video.src = URL.createObjectURL(file);
    //   video.addEventListener("loadedmetadata", () => {
    //     this.video.duree = this.formatDuration(video.duration);
    //     console.log(this.video.duree);
    //   });
    // },

    //Récupération des images de campagne
    selectImageAccueilMob(event) {
      this.imgHomeMob = event.target.files[0]; // Get the selected image or video
      console.log(this.imgHomeMob);
    },

    selectImageLiveMob(event) {
      this.imgLiveMob = event.target.files[0]; // Get the selected image or video
      console.log(this.imgLiveMob);
    },

    selectImageLiveWeb(event) {
      this.imgLiveWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.imgLiveWeb);
    },

    selectImageHomeWeb(event) {
      this.imgHomeWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.imgHomeWeb);
    },

    //Récupération des videos de campagne
    selectVideoAccueilMob(event) {
      this.videoHomeMob = event.target.files[0]; // Get the selected image or video
      console.log(this.videoHomeMob);
    },

    selectVideoLiveMob(event) {
      this.videoLiveMob = event.target.files[0]; // Get the selected image or video
      console.log(this.videoLiveMob);
    },

    selectVideoLiveWeb(event) {
      this.videoLiveWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.videoLiveWeb);
    },

    selectVideoHomeWeb(event) {
      this.videoHomeWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.videoHomeWeb);
    },

    //Récupération des gif de campagne
    selectGifAccueilMob(event) {
      this.gifHomeMob = event.target.files[0]; // Get the selected image or video
      console.log(this.gifHomeMob);
    },

    selectGifLiveMob(event) {
      this.gifLiveMob = event.target.files[0]; // Get the selected image or video
      console.log(this.gifLiveMob);
    },

    selectGifLiveWeb(event) {
      this.gifLiveWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.gifLiveWeb);
    },

    selectGifHomeWeb(event) {
      this.gifHomeWeb = event.target.files[0]; // Get the selected image or video
      console.log(this.gifHomeWeb);
    },

    selectImageFileCampagne() {
      this.fileCampagne = "images";
      console.log(this.fileCampagne);
    },

    selectVideoFileCampagne() {
      this.fileCampagne = "videos";
      console.log(this.fileCampagne);
    },

    selectGifFileCampagne() {
      this.fileCampagne = "gif";
      console.log(this.fileCampagne);
    },
    // This function is called when the user clicks the upload button
    createCampagne() {
      this.titre = "Création d'une campagne";
      this.progress = true;
      this.progressValue = 0;

      this.process = true;
      this.modalOk = false;
      const onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        console.log(`Upload progress: ${this.progress}% et ${progress}`);
      };

      console.log(this.fileCampagne);
      if (this.fileCampagne == "images") {
        Api.postFormData(
          `/publicite/rest/publicite/create-publicite-images`,
          {
            annonceur: this.campagne.annonceur,
            budget: this.campagne.budget,
            description: this.campagne.description,
            endDate: new Date(this.campagne.endDate),
            frequence: this.campagne.frequence,
            message: this.campagne.message,
            nom_campagne: this.campagne.title,
            url: this.campagne.url,
            startDate: new Date(this.campagne.startDate),

            img_mob_acc: this.imgHomeMob,
            img_mob_live: this.imgLiveMob,
            img_web_acc: this.imgHomeWeb,
            img_web_live: this.imgLiveWeb,
          },
          onUploadProgress
        )
          .then(function (response) {
            Swal.fire("Success!", "Campagne ajoutée", "success");
            console.log(response);
            this.progress = false;
            this.process = false;
          })
          .catch((error) => {
            this.process = false;
            this.progress = 0;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.fileCampagne == "gif") {
        Api.postFormData(
          `/publicite/rest/publicite/create-publicite-gif`,
          {
            annonceur: this.campagne.annonceur,
            budget: this.campagne.budget,
            description: this.campagne.description,
            endDate: new Date(this.campagne.endDate),
            frequence: this.campagne.frequence,
            message: this.campagne.message,
            url: this.campagne.url,
            nom_campagne: this.campagne.title,
            startDate: new Date(this.campagne.startDate),

            gif_mob_acc: this.gifHomeMob,
            gif_mob_live: this.gifLiveMob,
            gif_web_acc: this.gifHomeWeb,
            gif_web_live: this.gifLiveWeb,
          },
          onUploadProgress
        )
          .then(function (response) {
            Swal.fire("Success!", "Campagne ajoutée", "success");
            console.log(response);
            this.progress = false;
            this.process = false;
          })
          .catch((error) => {
            this.process = false;
            this.progress = 0;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.fileCampagne == "videos") {
        Api.postFormData(
          `/publicite/rest/publicite/create-publicite-videos`,
          {
            annonceur: this.campagne.annonceur,
            budget: this.campagne.budget,
            description: this.campagne.description,
            endDate: new Date(this.campagne.endDate),
            frequence: this.campagne.frequence,
            message: this.campagne.message,
            url: this.campagne.url,
            nom_campagne: this.campagne.title,
            startDate: new Date(this.campagne.startDate),

            vid_mob_acc: this.videoHomeMob,
            vid_mob_live: this.videoLiveMob,
            vid_web_acc: this.videoHomeWeb,
            vid_web_live: this.videoLiveWeb,
          },
          onUploadProgress
        )
          .then(function (response) {
            Swal.fire("Success!", "Campagne ajoutée", "success");
            console.log(response);
            this.progress = false;
            this.process = false;
          })
          .catch((error) => {
            this.process = false;
            this.progress = 0;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }
    },

    goToPage(page) {
      // Vérifier si la page demandée est valide
      if (page >= 0 && page <= this.pageCount) {
        this.currentPage = page;
      }
    },
  },

  mounted() {
    this.spinner = true;
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };

    Api.get("/publicite/api/publicite/all-ads", onUploadProgress)
      .then((res) => {
        this.campagneList = res.data.content;
        this.spinner = false;
        console.log(res);
        this.filterVideos("all");
      })
      .catch((error) => {
        this.spinner = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },
  watch: {
    startDate(newVal) {
      this.endDate = newVal; // Mettre la valeur minimale à la nouvelle valeur de startDate
    },
  },
  computed: {
    filteredVideosList() {
      if (!this.startDate || !this.endDate) {
        if (this.searchQuery) {
          return this.campagneList
            .slice()
            .reverse()
            .filter((campagne) => {
              const title = campagne.nom_campagne.toLowerCase();
              const searchQuery = this.searchQuery.toLowerCase();

              return title.includes(searchQuery);
            });
        }
        return this.campagneList.slice().reverse();
      }

      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();

      return this.campagneList
        .slice()
        .reverse()
        .filter((campagne) => {
          const createdAt = new Date(campagne.startDate).getTime();
          const title = campagne.nom_campagne.toLowerCase();
          const searchQuery = this.searchQuery.toLowerCase();

          return (
            createdAt >= start &&
            createdAt <= end &&
            title.includes(searchQuery)
          );
        });
    },
    pages() {
      // Générer une liste de nombres de pages à afficher dans la pagination
      let pages = [];
      for (let i = 0; i <= this.pageCount - 1; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageCount() {
      return Math.ceil(this.filteredVideosList.length / this.pageSize);
    },
    paginatedVideos() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredVideosList.slice(start, end);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <loader-process :visible="process" :progress="progress" :title="titre" />
    <div class="row mb-3">
      <div class="col-lg-4">
        <br /><br />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher une campagne"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de début</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="startDate"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de fin</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="endDate"
                  :min="startDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-2">
        <br />
        <button
          type="submit"
          style="border-radius: 8px; width: 100%"
          class="btn c2play-primary mt-3"
          @click="showModal"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i>
            {{ $t("addButton") }}</span
          >
        </button>
      </div>
    </div>
    <!-- <div class="row">
      <div style="display: flex; align-items: center; justify-content: center">
        <button
          @click="filterArticles('all')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'all' }"
        >
          Toutes
        </button>
        <button
          @click="filterArticles('published')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'published' }"
        >
          Créées
        </button>
        <button
          @click="filterArticles('unpublished')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'unpublished' }"
        >
          Validées
        </button>
        <button
          @click="filterArticles('unpublished')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'unpublished' }"
        >
          Activées
        </button>
      </div>
    </div> -->

    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px">Fichier</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Annonceur</th>
                    <th scope="col">Click</th>
                    <th scope="col">Date de debut</th>
                    <th scope="col">Date de fin</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <displayLiteCard
                    v-for="campagne in paginatedVideos"
                    :key="campagne.id"
                    :campagne-props="campagne"
                  ></displayLiteCard>
                </tbody>
              </table>

              <div
                style="text-align: center; font-size: 3em"
                v-if="spinner == true"
              >
                <b-spinner variant="success" role="status"></b-spinner>
              </div>

              <nav
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                v-if="pages.length > 1"
              >
                <ul class="pagination">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 0 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 20px 0 0 20px;
                      "
                      @click="goToPage(currentPage - 1)"
                    >
                      Previous
                    </button>
                  </li>
                  <li v-for="page in pages" :key="page" class="page-item">
                    <button
                      class="page-link"
                      :class="{ active2play: currentPage === page }"
                      style="
                        height: 45px;
                        width: 45px;
                        border-radius: 50%;
                        margin: 5px;
                      "
                      @click="goToPage(page)"
                    >
                      {{ page + 1 }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === pageCount - 1 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 0 20px 20px 0;
                      "
                      @click="goToPage(currentPage + 1)"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      title="Créer une nouvelle campagne"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectname" class="col-form-label"
                >Nom de l'annonceur</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="campagne.annonceur"
                placeholder="Saisir le nom de l'entreprise de l'annonce"
              />
            </div>

            <div class="col-lg-6">
              <label class="col-form-label">Titre de campagne</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="campagne.title"
                  placeholder="Saisir le titre de la campagne"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label class="col-form-label">Dade de debut de la campagne</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="datetime-local"
                  v-model="campagne.startDate"
                  class="form-control"
                  :placeholder="$t('pages.videos.formulaire.years.placeholder')"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label"
                >Date de fin de la campagne</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="datetime-local"
                  v-model="campagne.endDate"
                  :placeholder="$t('pages.videos.formulaire.duree.placeholder')"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">Budget</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="campagne.budget"
                  class="form-control"
                  placeholder="Saisir le budget"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Fréquence de passage</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="campagne.frequence"
                  class="form-control"
                  placeholder="Saisir la fréquence de passage"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >URL du site web</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="campagne.url"
                  class="form-control"
                  placeholder="Saisir l'url du site"
                />
              </div>
            </div>
          </div>

          <br />
          <h4 class="mt-2">Selectionnez le type de fichier de campagne</h4>

          <b-tabs content-class="p-3">
            <b-tab active class="border-1" @click="selectImageFileCampagne">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Image</span>
              </template>
              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectname" class="col-form-label"
                    >Image mobile accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="selectImageAccueilMob($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectname" class="col-form-label"
                    >Image mobile live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="selectImageLiveMob($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectname" class="col-form-label"
                    >Image web accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="selectImageHomeWeb($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectname" class="col-form-label"
                    >Image web live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="selectImageLiveWeb($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="selectVideoFileCampagne">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Vidéo</span>
              </template>

              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Vidéo mobile accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectVideoAccueilMob($event)"
                      class="form-control"
                      accept=".MKV, .AVI, .MP4, MOV"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Vidéo mobile live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectVideoLiveMob($event)"
                      class="form-control"
                      accept=".MKV, .AVI, .MP4, MOV"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Vidéo web accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectVideoHomeWeb($event)"
                      class="form-control"
                      accept=".MKV, .AVI, .MP4, MOV"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Vidéo web live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectVideoLiveWeb($event)"
                      class="form-control"
                      accept=".MKV, .AVI, .MP4, MOV"
                    />
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="selectGifFileCampagne">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Gif</span>
              </template>

              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Gif mobile accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectGifAccueilMob($event)"
                      class="form-control"
                      accept=".gif"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Gif mobile live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectGifLiveMob($event)"
                      class="form-control"
                      accept=".gif"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Gif web accueil</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectGifHomeWeb($event)"
                      class="form-control"
                      accept=".gif"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Gif web live</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="selectGifLiveWeb($event)"
                      class="form-control"
                      accept=".gif"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label">Message</label>
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="campagne.message"
                  placeholder="Saisir le message"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label">{{
                $t("pages.videos.formulaire.description.label")
              }}</label>
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="campagne.description"
                  placeholder="Saisir la description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="createCampagne"
              style="position: relative; left: 45%"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style></style>

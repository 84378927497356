<template>
  <tr>
    <td @click="showDetailModal">
      <!-- <router-link to="'/projects/overview/' + videoProps.id"> -->
      <div
        style="height: 120px; width: 150px"
        v-if="campagneProps.fichierPubMap.img_mob_acc"
      >
        <img
          style="
            height: 100%;
            width: 100%;
            image-rendering: auto;
            object-fit: cover;
            image-resolution: auto;
          "
          :src="campagneProps.fichierPubMap.img_mob_acc.url"
          alt
        />
      </div>

      <div
        style="height: 150px; width: 150px"
        v-else-if="campagneProps.fichierPubMap.vid_mob_acc"
      >
        <div
          style="
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            height: 100%;
            overflow: hidden;
          "
          itemscope
          itemtype="https://schema.org/VideoObject"
        >
          <video
            ref="videoPlayer"
            :src="campagneProps.fichierPubMap.vid_mob_acc.url"
            controls
            style="
              width: 100%;
              height: 80%;
              position: absolute;
              left: 0px;
              top: 0px;
              overflow: hidden;
            "
            width="720"
            height="480"
          ></video>
        </div>
      </div>

      <div style="height: 120px; width: 150px" v-else>
        <img
          style="
            height: 100%;
            width: 100%;
            image-rendering: auto;
            object-fit: cover;
            image-resolution: auto;
          "
          :src="campagneProps.fichierPubMap.gif_mob_acc.url"
          alt
        />
      </div>
      <!-- </router-link> -->
    </td>
    <td @click="showDetailModal">
      <!-- <router-link to="'/projects/overview/' + videoProps.id"> -->
      <h5 class="font-size-14 mb-1 capitalize">
        {{ campagneProps.nom_campagne }}
      </h5>
      <!-- </router-link> -->
    </td>

    <td>{{ campagneProps.annonceur }}</td>
    <td>{{ campagneProps.nbClick }}</td>
    <td>
      <span>{{ new Date(campagneProps.startDate).toLocaleString() }}</span>
    </td>
    <td>
      <span>{{ new Date(campagneProps.endDate).toLocaleString() }}</span>
    </td>
    <td>
      <span v-if="campagneProps.published == true" class="bg-success badge"
        >Activée</span
      >
      <span v-else class="bg-warning badge">Créée</span>
      <!-- <span v-if="role.name == 'ROLE_CREATOR'" class="bg-danger badge">{{ $t('roles.create') }}</span>
                        <span v-if="role.name == 'ROLE_AWARD'" class="bg-primary badge">Award</span>
                        <span v-if="role.name == 'ROLE_EDITOR'" class="bg-info badge">{{ $t('roles.edit') }}</span> -->
    </td>
    <td>
      <b-button
        class="c2play-primary"
        style="margin-left: 35%; margin-right: auto"
      >
        <b-dropdown
          class="card-drop"
          variant="white"
          menu-class="dropdown-menu-end"
          right
          toggle-class="p-0"
        >
          <template v-slot:button-content>
            <span style="color: #fff"
              ><i class="mdi mdi-dots-horizontal font-size-18"></i
            ></span>
          </template>
          <!-- <b-dropdown-item @click="showUpdateMode">Prévisualiser</b-dropdown-item> -->
          <b-dropdown-item @click="showDetailModal">Détails</b-dropdown-item>
          <b-dropdown-item @click="showUpdateModal">Modifier</b-dropdown-item>
          <!-- <b-dropdown-item @click="confirmValidation">Valider</b-dropdown-item> -->
          <b-dropdown-item
            @click="confirmDesactivationCampagne"
            v-if="campagneProps.published == true"
            >Désactiver</b-dropdown-item
          >
          <b-dropdown-item @click="confirmActivationCampagne" v-else
            >Activer</b-dropdown-item
          >
        </b-dropdown>
      </b-button>
    </td>
  </tr>

  <b-modal
    v-model="updateModal"
    id="modal-xl"
    size="xl"
    title="Modifier la campagne"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
        {{ authError }}
      </b-alert>
      <form>
        <div class="form-group row mb-2">
          <div class="col-lg-6">
            <label for="projectname" class="col-form-label"
              >Nom de l'annonceur</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <input
              id="projectname"
              name="projectname"
              type="text"
              class="form-control"
              v-model="campagne.annonceur"
              placeholder="Saisir le nom de l'entreprise de l'annonce"
            />
          </div>

          <div class="col-lg-6">
            <label class="col-form-label">Titre de campagne</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-md-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="campagne.title"
                placeholder="Saisir le titre de la campagne"
              />
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="form-group col-lg-6">
            <label class="col-form-label">Dade de debut de la campagne</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="datetime-local"
                v-model="campagne.startDate"
                class="form-control"
                :placeholder="$t('pages.videos.formulaire.years.placeholder')"
              />
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label for="projectbudget" class="col-form-label"
              >Date de fin de la campagne</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="datetime-local"
                v-model="campagne.endDate"
                :placeholder="$t('pages.videos.formulaire.duree.placeholder')"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="form-group col-lg-6">
            <label for="projectname" class="col-form-label">Budget</label>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                v-model="campagne.budget"
                class="form-control"
                placeholder="Saisir le budget"
              />
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label for="projectname" class="col-form-label"
              >Fréquence de passage</label
            >
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                v-model="campagne.frequence"
                class="form-control"
                placeholder="Saisir la fréquence de passage"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="form-group col-lg-6">
            <label for="projectname" class="col-form-label"
              >URL du site web</label
            >
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                v-model="campagne.url"
                class="form-control"
                placeholder="Saisir l'url"
              />
            </div>
          </div>
        </div>
        <!-- <br />
        <h4 class="mt-2">Selectionnez le type de fichier de campagne</h4>

        <b-tabs content-class="p-3">
          <b-tab active class="border-1">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Image</span>
            </template>
            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectname" class="col-form-label"
                  >Image mobile accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="file"
                    @change="selectImageAccueilMob($event)"
                    class="form-control"
                    placeholder="Entre le lien de la vidéo..."
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectname" class="col-form-label"
                  >Image mobile live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="file"
                    @change="selectImageLiveMob($event)"
                    class="form-control"
                    placeholder="Entre le lien de la vidéo..."
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectname" class="col-form-label"
                  >Image web accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="file"
                    @change="selectImageHomeWeb($event)"
                    class="form-control"
                    placeholder="Entre le lien de la vidéo..."
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectname" class="col-form-label"
                  >Image web live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="file"
                    @change="selectImageLiveWeb($event)"
                    class="form-control"
                    placeholder="Entre le lien de la vidéo..."
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Vidéo</span>
            </template>

            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Vidéo mobile accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectVideoAccueilMob($event)"
                    class="form-control"
                    accept=".MKV, .AVI, .MP4, MOV"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Vidéo mobile live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectVideoLiveMob($event)"
                    class="form-control"
                    accept=".MKV, .AVI, .MP4, MOV"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Vidéo web accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectVideoHomeWeb($event)"
                    class="form-control"
                    accept=".MKV, .AVI, .MP4, MOV"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Vidéo web live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectVideoLiveWeb($event)"
                    class="form-control"
                    accept=".MKV, .AVI, .MP4, MOV"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Gif</span>
            </template>

            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Gif mobile accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectGifAccueilMob($event)"
                    class="form-control"
                    accept=".gif"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Gif mobile live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectGifLiveMob($event)"
                    class="form-control"
                    accept=".gif"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Gif web accueil</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectGifHomeWeb($event)"
                    class="form-control"
                    accept=".gif"
                  />
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label for="projectbudget" class="col-form-label"
                  >Gif web live</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectbudget"
                    name="projectbudget"
                    type="file"
                    @change="selectGifLiveWeb($event)"
                    class="form-control"
                    accept=".gif"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs> -->

        <div class="form-group row mb-2">
          <div class="col-lg-6">
            <label for="projectdesc" class="col-form-label">Message</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="campagne.message"
                placeholder="Saisir le message"
              ></textarea>
            </div>
          </div>

          <div class="col-lg-6">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.videos.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="campagne.description"
                placeholder="Saisir la description"
              ></textarea>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-lg-12" style="margin-top: 10px">
          <button
            type="submit"
            class="btn c2play-primary"
            @click="updateCampagne"
            style="position: relative; left: 45%"
          >
            {{ $t("updateButton") }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>

  <b-modal
    v-model="detailModal"
    id="modal-xl"
    size="xl"
    title="Détails de la campagne"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 mb-2">
          <div
            style="height: 300px; width: 100%"
            v-if="campagneProps.fichierPubMap.img_mob_acc"
          >
            <img
              style="
                height: 100%;
                width: 100%;
                image-rendering: auto;
                object-fit: cover;
                image-resolution: auto;
              "
              :src="campagneProps.fichierPubMap.img_mob_acc.url"
              alt
            />
          </div>

          <div
            style="height: 300px; width: 100%"
            v-else-if="campagneProps.fichierPubMap.vid_mob_acc"
          >
            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                height: 100%;
                overflow: hidden;
              "
              itemscope
              itemtype="https://schema.org/VideoObject"
            >
              <video
                ref="videoPlayer"
                :src="campagneProps.fichierPubMap.vid_mob_acc.url"
                controls
                style="
                  width: 100%;
                  height: 80%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                width="720"
                height="480"
              ></video>
            </div>
          </div>

          <div style="height: 300px; width: 100%" v-else>
            <img
              style="
                height: 100%;
                width: 100%;
                image-rendering: auto;
                object-fit: cover;
                image-resolution: auto;
              "
              :src="campagneProps.fichierPubMap.gif_mob_acc.url"
              alt
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h5>Titre de la campagne</h5>
            <p>{{ campagneProps.nom_campagne }}</p>
          </div>
          <div>
            <h5>Responsable de la campagne</h5>
            <p>{{ campagneProps.annonceur }}</p>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div>
                <h5>Date de début</h5>
                <p>{{ new Date(campagneProps.startDate).toLocaleString() }}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <h5>Date de fin</h5>
                <p>{{ new Date(campagneProps.endDate).toLocaleString() }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div>
                <h5>Nombre de click</h5>
                <p>{{ campagneProps.nbClick }}</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <h5>Nombre de vue</h5>
                <p>{{ campagneProps.nb_vue }}</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <h5>Statut</h5>
                <p>
                  <span
                    v-if="campagneProps.published == true"
                    class="bg-success badge"
                    >Activée</span
                  >
                  <span v-else class="bg-warning badge">Créée</span>
                  <!-- <span v-if="role.name == 'ROLE_MODERATOR'" class="bg-warning badge">{{ $t('roles.mod') }}</span>
                        <span v-if="role.name == 'ROLE_CREATOR'" class="bg-danger badge">{{ $t('roles.create') }}</span>
                        <span v-if="role.name == 'ROLE_AWARD'" class="bg-primary badge">Award</span>
                        <span v-if="role.name == 'ROLE_EDITOR'" class="bg-info badge">{{ $t('roles.edit') }}</span> -->
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div>
              <h5>Consulter le site</h5>
              <p>
                <a target="_blank" :href="campagneProps.url">{{
                  campagneProps.url
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <h5>Message</h5>
        <div>{{ campagneProps.message }}</div>
      </div>
      <div class="row">
        <h5>Description</h5>
        <div>{{ campagneProps.description }}</div>
      </div>
    </div>
  </b-modal>

  <loaderProcessVue
    :title="titre"
    :progress="progress"
    :visible="process"
  ></loaderProcessVue>
</template>

<script>
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
export default {
  components: {
    loaderProcessVue,
  },
  data() {
    return {
      progress: 0,
      searchQuery: "",
      process: false,
      campagne: {
        title: "",
        annonceur: "",
        startDate: "",
        endDate: "",
        description: "",
        durationPassage: "",
        message: "",
        url: "",
        frequence: 0,
        budget: 0,
      },
      updateModal: false,
      detailModal: false,
    };
  },
  mounted() {
    this.campagne.title = this.campagneProps.nom_campagne;
    this.campagne.annonceur = this.campagneProps.annonceur;
    this.campagne.startDate = this.campagneProps.startDate;
    this.campagne.endDate = this.campagneProps.endDate;
    this.campagne.description = this.campagneProps.description;
    this.campagne.message = this.campagneProps.message;
    this.campagne.budget = this.campagneProps.budget;
    this.campagne.frequence = this.campagneProps.frequence;
    this.campagne.url = this.campagneProps.url;
  },
  props: {
    campagneProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showUpdateModal() {
      this.updateModal = true;
    },

    showDetailModal() {
      this.detailModal = true;
    },

    confirmValidation() {
      Swal.fire({
        title: "Avez vous bien vérifié la campagne ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteCandidate();
        }
      });
    },

    confirmActivationCampagne() {
      Swal.fire({
        title: "Vous allez activer cette campagne !",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.activationCampagne();
        }
      });
    },

    confirmDesactivationCampagne() {
      Swal.fire({
        title: "Vous allez désactiver cette campagne !",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.desactivationCampagne();
        }
      });
    },

    activationCampagne() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Activation de la campagne en cours ...";
      Api.put(`/publicite/rest/publicite/publish-pub/${this.campagneProps.id}`)
        .then(function (response) {
          Swal.fire("Success!", "Campagne activé", "success");
          location.reload();
          this.process = false;
          console.log(response);
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    desactivationCampagne() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Désactivation de la campagne en cours ...";

      Api.put(
        `/publicite/rest/publicite/unpublish-pub/${this.campagneProps.id}`
      )
        .then(function (response) {
          Swal.fire("Success!", "Campagne désactivé", "success");
          location.reload();
          console.log(response);
          this.process = false;
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    updateCampagne() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Modification de la campagne";
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      Api.putFormData(
        `/publicite/rest/publicite/update-data-pub/${this.campagneProps.id}`,
        {
          annonceur: this.campagne.annonceur,
          budget: this.campagne.budget,
          description: this.campagne.description,
          endDate: new Date(this.campagne.endDate),
          frequence: this.campagne.frequence,
          message: this.campagne.message,
          nom_campagne: this.campagne.title,
          url: this.campagne.url,
          startDate: new Date(this.campagne.startDate),
        },
        { onUploadProgress }
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Campagne Modifiée avec succès", "success");
          console.log(response);
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style></style>
